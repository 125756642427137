<template>
    <div class="mangalist">
        <div class="list-top">
            <div class="titlebox">{{ title }}</div>
            <div class="searchbar">
                <div class="input">
                    <div class="icon">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/search.png)"></div>
                        </div>
                    </div>
                    <input type="text" maxlength="30" placeholder="请输入您要搜索的内容" v-model="search">
                </div>
                <button class="btn" @click="getData">搜索</button>
            </div>
        </div>
        <div class="itemlist">
            <div class="item" v-for="(item, index) in list" :key="index"
                @click="goto('/compositionShow', { id: item.id })">
                <div class="img">
                    <div class="picbox">
                        <div class="pic" :style="{ backgroundImage: 'url(' + baseUrl + item.imgUrl + ')' }"></div>
                    </div>
                </div>
                <div class="itemtitle">
                    <div class="atitle">{{ item.title }}</div>
                    <div class="time">{{ item.updateTime }}</div>
                </div>
            </div>
        </div>
        <div class="empty" v-if="message">{{ message }}</div>
        <div class="fenye" v-if="list.length > 0">
            <el-pagination background layout="prev, pager, next" :page-size="9" :total="allCount" :current-page="page"
                @current-change="handlepage" />
        </div>
    </div>
</template>
  
<script>
import { getManga } from "@/js/request";

export default {
    name: 'MangaList',
    components: {

    },
    props: ['title', 'cate'],
    data() {
        return {
            allCount: 0,//总页数
            search: '',
            list: [],
            page: 1,
            baseUrl: '',
            message: '',
            cateValue: 0,
        }
    },
    watch: {
        cate: {
            handler: function (newValue) {
                this.cateValue = newValue;
                this.page=1;
                this.getData();
            }
        },
    },
    methods: {
        goto(adress, param) {
            this.$router.push({ path: adress, query: param });
        },
        handlepage(e) {
            this.page = e;
            this.getData();
        },
        getData() {
            this.message = '';
            getManga(this.page, this.cateValue, this.search).then(res => {
                this.allCount = JSON.parse(res.headers['x-pagination']).totalCount;
                this.list = res.data;
                this.baseUrl = res.config.baseURL;
            }).catch(error => {
                if (error.response) {
                    this.list = [];
                    this.message = error.response.data.message;
                }
            });
        }
    },
    mounted() {
        this.$root.showRight();
        this.getData();
    }
}
</script>
  
<style lang="scss">
.mangalist {
    @include sys-boxsize(100%, auto, 20px, #fff);
    padding: 40px 60px 20px;
    box-sizing: border-box;
    box-shadow: 0px 0px 50px 0px rgba(63, 101, 224, 0.05);

    .list-top {
        @include sys-flex(space-between, row);

        .titlebox {
            @include sys-text-hide(1);
            @include sys-font(30px, #000, bold);
            line-height: 50px;
            flex: 1;
        }

        .searchbar {
            flex: none;
            display: flex;
            align-items: center;

            .input {
                @include sys-boxsize(360px, 50px, 25px, #F6F6F6);
                display: flex;
                align-items: center;
                margin-right: 10px;
                padding: 0 20px;
                box-sizing: border-box;

                .icon {
                    @include sys-picbox(20px, 20px);
                    margin-right: 10px;
                    flex: none;
                }

                input {
                    @include sys-font(20px, #333);
                    flex: 1;
                    height: 100%;
                    background-color: transparent;
                }
            }

            .btn {
                @include sys-text-hide(1);
                @include sys-radius-btn(120px, 50px, $acolor);
                @include sys-font(20px, #fff);
                cursor: pointer;
            }
        }

    }

    .itemlist {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 30px);
        margin: 40px -15px 0 -15px;

        .item {
            display: flex;
            flex-direction: column;
            width: 33.33%;
            height: 310px;
            padding: 0 15px;
            box-sizing: border-box;
            margin-bottom: 20px;
            cursor: pointer;

            &:hover {
                .itemtitle {
                    .atitle {
                        color: $acolor;
                    }
                }
            }

            .img {
                width: 293px;
                height: 192px;
                flex: none;
                position: relative;
                @include sys-picbox(100%, 100%, cover);
            }

            .itemtitle {
                flex: 1;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 16px 0 10px;

                .atitle {
                    @include sys-text-hide(2);
                    @include sys-font(20px, #000);
                    line-height: 26px;
                    transition: color .3s;
                }

                .time {
                    @include sys-text-hide(1);
                    @include sys-font(16px, #999);
                    line-height: 18px;
                }
            }
        }
    }
}
</style>